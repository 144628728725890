.navbar {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 5px 0;
	z-index: 999;
	background-color: #FAFBFB;
	
	.container {
		width: 80%;
		margin: 0 auto;
		display: flex;
		align-items: center;

		.menu-btn {
			display: none;
			cursor: pointer;
		}

		.logo {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.links {
			flex: 3;
			display: flex;
			align-items: center;
			justify-content: right;
			gap: 30px;
			
			.link {
				font-size: 14px;
				color: #888;
				text-decoration: none;
				transition: .4s ease all;
				text-wrap: nowrap;
				font-weight: bold;

				&:hover {
					color: #000;
				}
				
				&.active {
					color: #000;
				}
			}
		}
		@media (max-width: 1200px) {
			flex-direction: column;
			align-items: stretch;
			width: 90%;

			.logo {
				width: 100%;
				
				.menu-btn {
					display: block;
				}
			}
			
			.links {
				display: none;
				padding: 20px 0;
				flex-direction: column;
				
				&.active {
					display: flex;
				}
			}
		}
	}
}