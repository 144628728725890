.aboutUs {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 120px 0;

	.container {
		width: 85%;
		margin: 0 auto;
		display: flex;
		align-items: center;

		.content {
			width: 80%;
			margin: 0 auto;

			h3 {
				font-size: 38px;
				text-align: center;
				margin-bottom: 40px;
			}

			p {
				text-align: justify;
				color: #444;
			}
		}

		@media (max-width: 1024px) {
			width: 90%;
		}
	}

	@media (max-width: 768px) {
		padding: 60px 0;

		.container {
			width: 90%;

			.content {
				width: 100%;

				h3 {
					font-size: 26px;
				}

				p {
					font-size: 14px;
				}
			}
		}
	}
}