@import "../../styles/_variables";

.video-container {
	position: relative;
	height: 100vh;
	width: 100%;
	overflow: hidden;
}

#background-video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -1;
	transform: translate(-50%, -50%);
	object-fit: contain;
}

.video-content {
	display: flex;
	background-color: transparent;
	height: 100vh;
	align-items: center;
	justify-content: center;
}

#elementId {
	background-color: transparent;
	outline: none;
	border: none;
}

#mute {
	position: absolute;
	right: 40px;
	z-index: 9;
	top: 20px;
	background-color: transparent;
	outline: none;
	border: none;

	@media(max-width:768px) {
		right: 20px;
	}
}