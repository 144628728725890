.undp-content {
	width: 80%;
	display: flex;
	margin: 0 auto;
	align-items: center;

	img {
		width: 200px;
		height: 150px;
	}

	@media(max-width: 912px) {
		width: 90%;
		flex-direction: column;
		gap: 50px;

		.img {
			width: 100%;

			.img-div {
				width: 100%;
			}
		}
	}
}