@import '../../styles/_variables';

.annoucment {
	background-color: #F8F8F8;
	display: flex;
	align-items: center;
	padding: 5px 0;

	.container {
		width: 80%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.contact-info {
			display: flex;
			align-items: center;
			gap: 20px;

			> .email {
				a {
					color: #000;
					text-decoration: none;
				}
			}
			div {
				display: flex;
				align-items: center;
				gap: 4px;
				font-size: 12px;
			}
		}

		.lang {
			font-size: 12px;

			button {
				outline: none;
				border: none;
				cursor: pointer;
				background-color: transparent;

				span {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			span {
				margin: 0 5px;
				padding: 3px 5px;
				background-color: #000;
				color: #fff;
				font-weight: bold;
				cursor: pointer;
				transition: .4s ease all;

				&:hover {
					background-color: #888;
				}
			}
		}

		@media (max-width: 1024px) {
			width: 95%;
		}

		@media (max-width: 768px) {
			justify-content: center;

			.contact-info {
				display: none;
			}
		}
	}
}