@import "../../../styles/__variables.scss";

.section {
  margin-top: 20px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;

  .section-title {
    font-size: 18px;
    color: $primary-gray;
    padding: 10px;
    padding-left: 30px;
    background-color: $dark-secondary;
    color: #fff;
  }

  .table-custom {
    background-color: $dark-secondary;
    border: 1px solid $dark-secondary;
    margin: 0 30px;
    margin-top: 30px;
    padding: 20px;

    .table-actions {
      display: flex;
      justify-content: space-between;

      .add {
        background-color: $secondary-background;
        padding: 10px 20px;
        text-transform: uppercase;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
        transition: 0.4s ease all;
        display: flex;
        align-items: center;
        color: #fff;

        &:hover {
          background-color: $dark-secondary;
          border: 1px solid $secondary-background;
        }
      }

      .delete {
        background-color: $secondary-background;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        text-transform: uppercase;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
        transition: 0.4s ease all;
        display: none;

        &:hover {
          background-color: red;
        }

        &.acitve {
          display: block;
        }
      }
    }

    .table-title {
      color: $primary-gray;
      margin-bottom: 10px;
      font-size: 18px;
      color: #fff;
    }

    .table-desc {
      color: $primary-gray;
    }

    .action-btn {
      padding: 5px;
      text-transform: uppercase;
      border-radius: 20px;
      font-size: 12px;
      cursor: pointer;
      transition: 0.4s ease all;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary-background;

      &:hover {
        background-color: $dark-secondary;
        border: 1px solid $secondary-background;
      }
    }

    .table-grid {
      margin-top: 20px;

      table {
        margin-top: 15px;
        width: 100%;
        text-align: center;
        border: 1px solid #fff;
        border-collapse: collapse;

        tbody {
          overflow: auto;
        }

        tr {
          border: 1px solid #fff;
          padding: 5px;

          td,
          th {
            padding: 5px 10px;
            border: 2px solid $dark-primary;
            color: #fff;
          }
        }

      }

      .pagination {
        margin-top: 20px;
        color: #fff;
      }
    }
  }
}