.hero {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../public/images/about-bg.jpg");
  background-size: cover;
  background-position: center;

  .hero-content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

		h3 {
			font-size: 48px;
			color: #fff;
      text-transform: capitalize;
		}

    a {
        font-size: 18px;
        color: #fff;
        text-decoration: none;
        margin-right: 5px;
    }

    span {
        font-size: 18px;
        color: #aaa;
    }
  }
}

