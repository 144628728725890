@import '../../../styles/_variables';

.contact {
  width: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  margin-bottom: 100px;

  .container {
    width: 85%;
    margin: 0 auto;

    h2 {
      text-align: center;
      color: $primary-red;
      font-size: 42px;
      font-family: 'Amaranth', sans-serif;

      span {
        color: $primary-blue;
      }
    }

    p {
      text-align: center;
      color: $primary-red;
      font-size: 18px;
      margin-top: 10px;
    }

    .row {
      margin-top: 80px;
      width: 100%;
      display: flex;
      justify-content: center;

      .info {
        width: 60%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        align-self: stretch;

        h3 {
          align-self: center;
          color: #000
        }

        >div {
          display: flex;
          gap: 20px;
          align-self: baseline;

          .icon {
            width: 40px;
            height: 40px;
            margin-top: 5px;
            background-color: #888;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
          }

          .text {
            h4 {
              font-size: 16px;
              color: $primary-red;
            }

            p {
              color: #000;
            }

            span {
              color: #000;
            }

            a {
              text-decoration: none;
              color: #000;
            }
          }
        }
      }

      .form {
        flex: 1;
        align-self: center;

        form {
          width: 80%;
          margin: 0 auto;
          background-color: #fff;
          border: 1px solid #000;
          padding: 20px;

          @media(max-width: 1200px) {
            width: 80%;
          }

          .form-group {
            margin-bottom: 30px;

            input,
            textarea {
              width: 100%;
              border: none;
              border-bottom: 1px solid $primary-red;
              font-size: 16px;
              padding: 5px;
            }
          }

          button {
            padding: 10px 20px;
            background-color: $primary-red;
            color: #fff;
            font-size: 16px;
            border: none;
            outline: none;
            transition: 0.4s ease all;
            cursor: pointer;

            &:hover {
              background-color: $primary-blue;
            }
          }


        }
      }
    }



    @media(max-width: 1024px) {
      width: 90%;
    }

  }

  @media(max-width: 768px) {
    min-height: 100vh;
    height: auto;

    .container {
      width: 90%;

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }

      .row {
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;

        .info {
          align-self: center;
          justify-content: center;
          width: 90%;
          text-align: center;

          >div {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;


            .icon {
              width: 30px;
              height: 30px;

            }
          }
        }

        .form {
          width: 100%;
          display: flex;
          align-items: center;

          form {
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}