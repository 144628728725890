@import "../../styles/_variables";

.footer {
  width: 100%;
  background-color: rgba($color: #000, $alpha: 0.8);
  color: #fff;
  overflow-x: hidden;

  .container {
    padding: 40px 0;
    width: 55%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    .about-footer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .text {
        width: 80%;
      }
    }

    @media (max-width: "1200px") {
      width: 75%;

      .about {
        .text {
          width: 100%;
        }
      }
    }

    .column {
      width: 40%;
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 18px;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          font-size: 14px;
          color: #fff;
          text-decoration: none;
        }
      }
    }
    @media (max-width: "768px") {
      width: 90%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .column {
        width: 100%;
        align-items: flex-start;

        h3 {
          font-size: 20px;
        }
      }
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #fff;
    // background-color: rgba($color: #000, $alpha: 0.8);
    color: #fff;
    text-transform: lowercase;
    width: 55%;
    margin: 0 auto;
    padding: 10px 0;

    @media (max-width: "1200px") {
      width: 75%;
    }

    @media (max-width: "768px") {
      width: 90%;
    }

    .left {
      display: flex;
      flex: 1;

      span {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      strong {
        font-size: 14px;
        font-style: italic;
        font-weight: normal;
        cursor: pointer;
        transition: 0.4s ease all;
        text-transform: uppercase;

        &:hover {
          color: #fff;
        }
      }
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 3px;
      justify-content: flex-end;

      .icon {
        text-decoration: none;
        padding: 5px;
        font-size: 12px;
        color: #fff;

        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }

    @media (max-width: "400px") {
      width: 90%;
      flex-direction: column;

      .left {
        order: 2;
        margin-top: 5px;
      }
    }
  }
}
