@import "../../styles/_variables";

.about {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 140px 0;
  // background-color: rgba($color: #000, $alpha: 0.8);
  background-color: #FAFBFB;

  .container {
    width: 85%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    color: #000;

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-family: 'Amaranth', sans-serif;
        text-transform: uppercase;
				font-weight: normal;
        font-size: 42px;
        margin-bottom: 20px;
      }
			
      p {
        font-family: 'Amaranth', sans-serif;
        margin-bottom: 15px;
      }
    }
		
    .img {
			flex: 1;
      display: flex;
      align-items: center;

      .img-div {
        width: 80%;
				margin: 0 auto;
        height: 300px;
        display: flex;
        align-items: center;
        background-color: #fff;
				
        img {
					width: 95%;
          height: 95%;
          object-fit: cover;
          object-position: center;
          margin: 0 auto;
        }
      }
    }
		
    .btns {
			margin-top: 20px;
      a {
				margin: 0 10px;
        padding: 10px;
        background-color: #000;
				font-family: 'Montserrat', sans-serif;
				color: #fff;
				transition: .4s ease all;
				text-decoration: none;

				&:hover {
					background-color: rgba($color: #000, $alpha: 0.4);
				}
      }
			
			.results {
				background-color: $primary-red;
				&:hover {
					background-color: rgba($color: $primary-red, $alpha: 0.7);
				}
			}
    }

		@media(max-width: 912px) {
			width: 90%;
			flex-direction: column;
			gap: 50px;

			.img {
				width: 100%;

				.img-div {
					width: 100%;
				}
			}
		}

		@media(max-width: 500px) {
			.content {
				h2 {
					font-size: 32px;
				}
				p {
					font-size: 14px;
				}

				.btns {

					a {
						padding: 5px;
					}
				}
			}
		}
  }
}
