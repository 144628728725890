.events {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 90%;
	margin: 0 auto;
	gap: 20px;
	margin-top: 70px;
}

.eventCard {
	width: 32%;
	height: 500px;
	overflow: hidden;

	a {
		text-decoration: none;
		color: #000;

	}

	.eventCardBody {
		height: 300px;

		img {
			height: 100%;
			width: 100%;
			border-radius: 10px;
		}
	}

	.eventCardFooter {
		h3 {
			margin-top: 20px;
			font-size: 20px;
		}

		p {
			height: 70px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			color: #888;
		}
	}

}

@media(max-width: 1200px) {
	.eventCard {
		width: 45%;
	}
}

@media(max-width: 768px) {
	.eventCard {
		width: 90%;
		margin: 0 auto;
	}
}