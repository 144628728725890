.events {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 90%;
	margin: 0 auto;
	gap: 20px;
	background-color: #FAFBFB;
}

.eventCard {
	width: 32%;
	height: 500px;
	overflow: hidden;

	a {
		text-decoration: none;
		color: #000;

	}

	.eventCardBody {
		height: 300px;

		img {
			height: 100%;
			width: 100%;
			border-radius: 10px;
		}
	}

	.eventCardFooter {
		h3 {
			margin-top: 20px;
			font-size: 20px;
		}

		p {
			height: 70px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			color: #888;
		}
	}

}

@media(max-width: 1200px) {
	.eventCard {
		width: 45%;
	}
}

@media(max-width: 768px) {
	.eventCard {
		width: 90%;
	}
}

.allNews {
	display: flex;
	align-items: center;
	justify-content: center;
	// padding-bottom: 40px;
	background-color: #FAFBFB;

	button {
		background-color: transparent;
		padding: 10px;
		outline: none;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		border-radius: 10px;
		border: 1px solid #888;

		&:hover {
			background-color: #FAFBFB90;
			color: #888;
		}
	}
}

.eventsWrapper {
	padding-top: 30px;
	background-color: #FAFBFB;

	.headline {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 50px 0;

		h2 {
			line-height: 60px;
			padding: 0 10px;
			text-align: center;
			border-top: 1px solid #000;
			border-bottom: 1px solid #000;
			text-transform: uppercase;
			background-color: #FAFBFB;

		}
	}
}

.noData {
	margin: 0 auto;
}