.login {
	width: 100%;
	height: 100vh;
	background: #355c7d; /* fallback for old browsers */
	background: -webkit-linear-gradient(
			to right,
			#c06c84,
			#6c5b7b,
			#355c7d
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
			to right,
			#c06c84,
			#6c5b7b,
			#355c7d
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	display: flex;
	align-items: center;

	.container {
			width: 85%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;

			.form {
					width: 50%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h2 {
							color: #fff;
					}

					.input {
							width: 300px;

							input {
									width: 100%;
									padding: 5px;
									background-color: transparent;
									color: #fff;
									margin-bottom: 15px;
									border: 1px solid #fff;
									padding: 10px;
									border-radius: 20px;
							}
					}

					button {
							width: 300px;
							border-radius: 20px;
							padding: 10px;
							background-color: #fff;
							outline: none;
							border: none;
							transition: .4s ease all;
							margin: 0 auto;

							&:hover {
									background-color: #ffffff50;
							}
					}
			}
	}
}
