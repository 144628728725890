@import "../../../styles/__variables.scss";

.sidebar {
  flex: 1;
  background-color: $primary-background;
  color: $primary-gray;
  box-shadow: rgba($color: $primary-background, $alpha: 0.44) 6px 2px 16px 0px,
    rgba($color: $primary-background, $alpha: 0.8) -6px -2px 16px 0px;
  position: relative;
  user-select: none;

  .logo {
    padding: 0 20px;
    margin-top: 20px;
    font-size: 26px;
    color: #fff;
  }

  .bar-title {
    padding: 0 20px;
    color: $primary-gray;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    color: #fff;
  }

  .dropdown {
    .dropdown-title {
      color: #fff;
      margin-top: 10px;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px 20px;
      text-decoration: none;

      &.active {
        background-color: $secondary-background;
      }

      .icon-span {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .dropdown-list {
      padding-left: 30px;
      display: none;
      background-color: $dark-primary;

      &.active {
        display: block;
        animation: dropdownExpand 0.3s;
      }
    }
  }

  .link {
    color: #fff;
    margin-top: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 20px;
    text-decoration: none;

    &.active {
      background-color: $secondary-background;
    }

    &:hover {
      background-color: $secondary-background;
    }

    span {
      margin-top: 2px;
    }

    .icon-span {
      margin-left: auto;
    }
  }

  .menu-bar {
    margin-top: 40px;
  }

  .more-bar {
    margin-top: 20px;
  }

  .footer-sidebar {
    position: absolute;
    bottom: 10px;

    button, a {
      border: none;
      background: transparent;
      width: 100%;

      &:hover {
        color: $secondary-background;
        background-color: transparent;
      }


    }
  }

  .close-menu {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    transition: 0.4s ease all;
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }

  @media (max-width: 1600px) {
    flex: 2;
  }

  @media (max-width: 991px) {
    display: none;

    &.active {
      display: block;
      position: absolute;
      z-index: 999;
      height: 100%;
      width: 30%;
      animation: slideOpen 0.2s;
    }
  }
}

@keyframes slideOpen {
  0% {
    width: 0%;
    display: none;
  }

  100% {
    width: 30%;
  }
}

@keyframes dropdownExpand {
  0% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(0%);
  }
}