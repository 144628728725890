.blog {
    width: 100%;
    display: flex;
    align-items: center;

    .container {
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .content {
            padding: 40px 0;
            width: 60%;
            overflow-x: hidden;
            
            .img {
                width: 100%;

                img {
                    width: 100%;
                    max-height: 400px;
                    object-fit: cover;
                    object-position: center;
                }
            }

            p {
                text-align: justify;
            }

            .files {
                display: flex;
                justify-content: space-between;

                img {
                    width: 48%;
                }
            }
        }

        @media (max-width:1024px) {
            width: 90%;

            .content {
                width: 80%;

               
            }
        }

        @media (max-width:768px) {
            width: 90%;

            .content {
                width: 100%;

                .text {
                    h2 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 14px;
                    }
                }

               
            }
        }

       
    }
}