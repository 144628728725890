@import "../../../styles/_variables";

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-y: hidden;

  .content {
    flex: 7;
    overflow-y: scroll;
    height: auto;
    background-color: $dark-primary;

    @media (max-width: 1600px) {
      flex: 8;
    }
  }
}

.custom-modal {
  background-color: rgba($color: $dark-primary, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  
  .modal-content {
    border: none;
  }
  
  .custom-modal-header {
    background-color: $dark-primary;
    border: none;
  }
  
  .custom-modal-body {
    background-color: $dark-secondary;
    border: none;

    input,
    textarea, select {
      background-color: $dark-primary;
      color: #fff;
      border: none;
      outline: none;
    }
  }
  .custom-modal-footer {
    background-color: $dark-primary;
    border: none;

    button {
      background-color: $primary-background;
      border: none;
      transition: .4s ease all;

      &:hover {
        background-color: $secondary-background;
      }
    }
  }
}